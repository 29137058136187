var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "imgPreviewContainer" }, [
    _c(
      "div",
      { staticClass: "titlePreview" },
      [
        _vm._v("\n    Full View\n    "),
        _c("WhpcTile", {
          attrs: {
            story: _vm.story,
            storyImage: _vm.cropImg,
            givenHeightName: "sizeFull",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "titlePreview" },
      [
        _vm._v("\n    Medium View\n    "),
        _c("WhpcTile", {
          attrs: {
            story: _vm.story,
            storyImage: _vm.cropImg,
            givenHeightName: "sizeMedium",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "titlePreview" },
      [
        _vm._v("\n    Small View\n    "),
        _c("WhpcTile", {
          attrs: {
            story: _vm.story,
            storyImage: _vm.cropImg,
            givenHeightName: "sizeSmall",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }