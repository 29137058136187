var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whpcTile" },
    [
      _c(
        "router-link",
        {
          staticClass: "link",
          attrs: { to: "/stories?id=" + _vm.story.id },
          nativeOn: {
            click: function ($event) {
              return _vm.setSelectedStory.apply(null, arguments)
            },
          },
        },
        [
          _c("AssetDownload", {
            class: _vm.height,
            attrs: {
              asset: _vm.story.thumbnailAsset,
              forceImage: _vm.storyImage,
              isStoryTile: true,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.setSelectedStory.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "middleCopy" },
        [
          _c(
            "router-link",
            {
              staticClass: "link",
              attrs: { to: "/stories?id=" + _vm.story.id },
              nativeOn: {
                click: function ($event) {
                  return _vm.setSelectedStory.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.story.subject)),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }