var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "imageCropperForStory" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("Thumbnail Previewer and Uploader"),
      ]),
      _c("input", {
        staticClass: "uploadFile",
        attrs: { type: "file", name: "image", accept: "image/*" },
        on: { change: _vm.setImage },
      }),
      _vm.imgSrc != ""
        ? _c(
            "div",
            {
              staticClass: "ImageCropperBtn",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.rotate.apply(null, arguments)
                },
              },
            },
            [_vm._v("Rotate")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cropperContainer" },
        [
          _c("vue-cropper", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imgSrc != "",
                expression: "imgSrc != ''",
              },
            ],
            ref: "cropper",
            attrs: {
              guides: true,
              "view-mode": 2,
              "drag-mode": "crop",
              aspectRatio: _vm.aspectRatio,
              "auto-crop-area": 0.5,
              "min-container-width": 100,
              "min-container-height": 180,
              background: true,
              rotatable: true,
              src: _vm.imgSrc,
              alt: "",
              cropend: _vm.cropImage,
              ready: _vm.cropImage,
              "img-style": { width: "400px", height: "400px" },
            },
          }),
        ],
        1
      ),
      _vm.isBrandStory
        ? _c("ImagePreviewerBrandStory", {
            attrs: { story: _vm.story, cropImg: _vm.cropImg },
          })
        : _c("ImagePreviewerWHPC", {
            attrs: { story: _vm.story, cropImg: _vm.cropImg },
          }),
      _c("ButtonSubmit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.cropImg,
            expression: "cropImg",
          },
        ],
        attrs: { copy: "Save Image" },
        on: { callback: _vm.selectedImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }